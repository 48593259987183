import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Records} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  userIds: [],
  userCount: 0,
  users: {},
  search: '',
  userRoles: {},
  changePassword: true,
  isRequesting: false,
  resetPage: false,
};

const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
const FETCH_USER_DETAILS_COMPLETED = 'FETCH_USER_DETAILS_COMPLETED';
const FETCH_USER_DETAILS_FAILED = 'FETCH_USER_DETAILS_FAILED';

const FETCH_USER_BY_ID = 'FETCH_USER_BY_ID';
const FETCH_USER_BY_ID_COMPLETED = 'FETCH_USER_BY_ID_COMPLETED';
const FETCH_USER_BY_ID_FAILED = 'FETCH_USER_BY_ID_FAILED';

const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
const UPDATE_USER_DETAILS_COMPLETED = 'UPDATE_USER_DETAILS_COMPLETED';
const UPDATE_USER_DETAILS_FAILED = 'UPDATE_USER_DETAILS_FAILED';

const CHANGE_PASSWORD_DETAILS = 'CHANGE_PASSWORD_DETAILS';
const CHANGE_PASSWORD_COMPLETED = 'CHANGE_PASSWORD_COMPLETED';
const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

const RESET_PAGE = 'RESET_PAGE';

export const fetchUserById = (userId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_USER_BY_ID});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.updateUserDetailsURL(userId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_USER_BY_ID_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_USER_BY_ID_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_USER_BY_ID_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const updateUserDetails = (userId, requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPDATE_USER_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: userId
          ? Records.updateUserDetailsURL(userId)
          : Records.addUserDetailsURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: UPDATE_USER_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: UPDATE_USER_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_USER_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchUserDetails = (page, search, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_USER_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchUserDetailsURL(page, search),
      });
      // console.log('response --FETCH_VENDOR_DETAILS_COMPLETED--', response);
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }

      if (status === true) {
        dispatch({type: FETCH_USER_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_USER_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_USER_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const UpdatePassword = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CHANGE_PASSWORD_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: requestData,
        url: Records.fetchChangePasswordURL(),
      });
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: CHANGE_PASSWORD_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CHANGE_PASSWORD_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CHANGE_PASSWORD_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_USER_DETAILS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_USER_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        userIds: payload?.user_list,
        userCount: payload?.total,
        total: payload?.total,
        search: payload?.query_string,
        users: payload?.users,
        changePassword: payload?.change_password,
        userRoles: payload?.user_roles,
      };
    case FETCH_USER_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        userIds: [],
        users: {},
      };
    case FETCH_USER_BY_ID:
      return {...state, isFetching: true};
    case FETCH_USER_BY_ID_COMPLETED:
      return {
        ...state,
        isFetching: false,
        user: payload?.user,
        userRoles: payload?.user_roles,
        teamTypes: payload?.team_type,
      };
    case FETCH_USER_BY_ID_FAILED:
      return {...state, isFetching: false};
    case CHANGE_PASSWORD_DETAILS:
      return {
        ...state,
        error: '',
      };
    case CHANGE_PASSWORD_COMPLETED:
      return {
        ...state,
        data: payload?.data,
      };
    case CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        error: payload.error,
      };

    case UPDATE_USER_DETAILS:
      return {...state, isRequesting: true};
    case UPDATE_USER_DETAILS_COMPLETED:
    case UPDATE_USER_DETAILS_FAILED:
      return {...state, isRequesting: false};
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };
    default:
      return state;
  }
};
