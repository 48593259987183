import {doRequest} from '../../helpers/network';
import {REQUEST_TYPE} from '../../constants';
import {Records} from '../../helpers/Urls';

const initialState = {
  loading: false,
  error: '',
  historyData: {},
  success: false,
  vehicleData: {},
  vehicleDocData: {},
  fetchVehicleloading: false,
  fetchGeneralVehicleloading: false,
  deleteDocsLoading: false,
  isPaymentDataSave: false,
  savedPaymentData: {},
  updatedDriverPhoneData: {},
};

export const FETCH_VEHICLE_DATA = 'FETCH_VEHICLE_DATA';
export const FETCH_VEHICLE_DATA_COMPLETED = 'FETCH_VEHICLE_DATA_COMPLETED';
export const FETCH_VEHICLE_DATA_FAILED = 'FETCH_VEHICLE_DATA_FAILED';

export const FETCH_GENERAL_VEHICLE_DATA = 'FETCH_GENERAL_VEHICLE_DATA';

export const FETCH_GENERAL_VEHICLE_DATA_COMPLETED =
  'FETCH_GENERAL_VEHICLE_DATA_COMPLETED';
export const FETCH_GENERAL_VEHICLE_DATA_FAILED =
  'FETCH_GENERAL_VEHICLE_DATA_FAILED';

export const EDIT_VEHCILE_DETAILS = 'EDIT_VEHCILE_DETAILS';
export const EDIT_VEHCILE_DETAILS_COMPLETED = 'EDIT_VEHCILE_DETAILS_COMPLETED';
export const EDIT_VEHCILE_DETAILS_FAILED = 'EDIT_VEHCILE_DETAILS_FAILED';

export const DELETE_VEHCILE_DOCS = 'DELETE_VEHCILE_DOCS';
export const DELETE_VEHCILE_DOCS_COMPLETED = 'DELETE_VEHCILE_DOCS_COMPLETED';
export const DELETE_VEHCILE_DOCS_FAILED = 'DELETE_VEHCILE_DOCS_FAILED';

export const ADD_VEHICLE = 'ADD_VEHICLE';
export const ADD_VEHICLE_COMPLETED = 'ADD_VEHICLE_COMPLETED';
export const ADD_VEHICLE_FAILED = 'ADD_VEHICLE_FAILED';

export const FETCH_VEHICLE_DETAILS = 'FETCH_VEHICLE_DETAILS';
export const FETCH_VEHICLE_DETAILS_COMPLETED =
  'FETCH_VEHICLE_DETAILS_COMPLETED';
export const FETCH_VEHICLE_DETAILS_FAILED = 'FETCH_VEHICLE_DETAILS_FAILED';

export const FETCH_VEHICLE_GET_DOCUMENT = 'FETCH_VEHICLE_GET_DOCUMENT';
export const FETCH_VEHICLE_GET_DOCUMENT_COMPLETED =
  'FETCH_VEHICLE_GET_DOCUMENT_COMPLETED';
export const FETCH_VEHICLE_GET_DOCUMENT_FAILED =
  'FETCH_VEHICLE_GET_DOCUMENT_FAILED';

export const FETCH_DRIVER_DETAILS = 'FETCH_DRIVER_DETAILS';
export const FETCH_DRIVER_DETAILS_COMPLETED = 'FETCH_DRIVER_DETAILS_COMPLETED';
export const FETCH_DRIVER_DETAILS_FAILED = 'FETCH_DRIVER_DETAILS_FAILED';

export const EDIT_DRIVER_DETAILS = 'EDIT_DRIVER_DETAILS';
export const EDIT_DRIVER_DETAILS_COMPLETED = 'EDIT_DRIVER_DETAILS_COMPLETED';
export const EDIT_DRIVER_DETAILS_FAILED = 'EDIT_DRIVER_DETAILS_FAILED';

export const ADD_DRIVER = 'ADD_DRIVER';
export const ADD_DRIVER_COMPLETED = 'ADD_DRIVER_COMPLETED';
export const ADD_DRIVER_FAILED = 'ADD_DRIVER_FAILED';

export const FETCH_DRIVER_LICENSE = 'FETCH_DRIVER_LICENSE';
export const FETCH_DRIVER_LICENSE_COMPLETED = 'FETCH_DRIVER_LICENSE_COMPLETED';
export const FETCH_DRIVER_LICENSE_FAILED = 'FETCH_DRIVER_LICENSE_FAILED';

export const EDIT_DRIVER_PHONE_DETAILS = 'EDIT_DRIVER_PHONE_DETAILS';
export const EDIT_DRIVER_PHONE_DETAILS_COMPLETED =
  'EDIT_DRIVER_PHONE_DETAILS_COMPLETED';
export const EDIT_DRIVER_PHONE_DETAILS_FAILED =
  'EDIT_DRIVER_PHONE_DETAILS_FAILED';

export const ADD_ROUTE_TAT = 'ADD_ROUTE_TAT';
export const ADD_ROUTE_TAT_COMPLETED = 'ADD_ROUTE_TAT_COMPLETED';
export const ADD_ROUTE_TAT_FAILED = 'ADD_ROUTE_TAT_FAILED';

export const EDIT_ROUTE_TAT = 'EDIT_ROUTE_TAT';
export const EDIT_ROUTE_TAT_COMPLETED = 'EDIT_ROUTE_TAT_COMPLETED';
export const EDIT_ROUTE_TAT_FAILED = 'EDIT_ROUTE_TAT_FAILED';

export const ADD_CITY_RECORD = 'ADD_CITY_RECORD';
export const ADD_CITY_RECORD_COMPLETED = 'ADD_CITY_RECORD_COMPLETED';
export const ADD_CITY_RECORD_FAILED = 'ADD_CITY_RECORD_FAILED';

export const EDIT_CITY_RECORD = 'EDIT_CITY_RECORD';
export const EDIT_CITY_RECORD_COMPLETED = 'EDIT_CITY_RECORD_COMPLETED';
export const EDIT_CITY_RECORD_FAILED = 'EDIT_CITY_RECORD_FAILED';

export const ADD_CONSIGNOR = 'ADD_CONSIGNOR';
export const ADD_CONSIGNOR_COMPLETED = 'ADD_CONSIGNOR_COMPLETED';
export const ADD_CONSIGNOR_FAILED = 'ADD_CONSIGNOR_FAILED';

export const EDIT_CONSIGNOR = 'EDIT_CONSIGNOR';
export const EDIT_CONSIGNOR_COMPLETED = 'EDIT_CONSIGNOR_COMPLETED';
export const EDIT_CONSIGNOR_FAILED = 'EDIT_CONSIGNOR_FAILED';

export const MARK_INACTIVE_VEHICLE = 'MARK_INACTIVE_VEHICLE';
export const MARK_INACTIVE_VEHICLE_COMPLETED =
  'MARK_INACTIVE_VEHICLE_COMPLETED';
export const MARK_INACTIVE_VEHICLE_FAILED = 'MARK_INACTIVE_VEHICLE_FAILED';
export const EDIT_DISTANCE = 'EDIT_DISTANCE';
export const EDIT_DISTANCE_DETAILS_COMPLETED =
  'EDIT_DISTANCE_DETAILS_COMPLETED';
export const EDIT_DISTANCE_DETAILS_FAILED = 'EDIT_DISTANCE_DETAILS_FAILED';

export const OPEN_WARNING_MODAL = 'OPEN_WARNING_MODAL';
export const CLOSE_WARNING_MODAL = 'CLOSE_WARNING_MODAL';
export const SWITCH_TAB = 'SWITCH_TAB';

export const FETCH_VEHICLE_DOCUMENT_HISTORY = 'FETCH_VEHICLE_DOCUMENT_HISTORY';
export const FETCH_VEHICLE_DOCUMENT_HISTORY_COMPLETED =
  'FETCH_VEHICLE_DOCUMENT_HISTORY_COMPLETED';
export const FETCH_VEHICLE_DOCUMENT_HISTORY_FAILED =
  'FETCH_VEHICLE_DOCUMENT_HISTORY_FAILED';

export const SAVE_PAYMENT_DATA = 'SAVE_PAYMENT_DATA';
export const SAVE_PAYMENT_DATA_COMPLETED = 'SAVE_PAYMENT_DATA_COMPLETED';
export const SAVE_PAYMENT_DATA_FAILED = 'SAVE_PAYMENT_DATA_FAILED';

export const warningOpen = (modalType, entityId, data, warning_active_tab) => {
  return (dispatch) => {
    dispatch({
      type: OPEN_WARNING_MODAL,
      payload: {modalType, entityId: entityId, data, warning_active_tab},
    });
  };
};

export const warningClose = (warning_active_tab = null) => {
  return (dispatch) => {
    dispatch({type: CLOSE_WARNING_MODAL, payload: {warning_active_tab}});
  };
};

export const switchTab = (warning_active_tab = null) => {
  return (dispatch) => {
    dispatch({type: SWITCH_TAB, payload: {warning_active_tab}});
  };
};
export const fetchVehicleDocumentHistory = (vehicleId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_VEHICLE_DOCUMENT_HISTORY});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchVehicleDocumentHistoryURL(vehicleId),
      });
      console.log('response --EDIT_VENDOR_DETAILS--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: FETCH_VEHICLE_DOCUMENT_HISTORY_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_VEHICLE_DOCUMENT_HISTORY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_VEHICLE_DOCUMENT_HISTORY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const fetchGeneralVehicleData = (id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_GENERAL_VEHICLE_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchGeneralVehicleDataURL(id),
      });
      console.log('response --FETCH_GENERAL_VEHICLE_DATA_DETAILS--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: FETCH_GENERAL_VEHICLE_DATA_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_GENERAL_VEHICLE_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_GENERAL_VEHICLE_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const fetchVehicleData = (id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_VEHICLE_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchVehicleDataURL(id),
      });
      console.log('response --FETCH_VEHICLE_DATA_DETAILS--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: FETCH_VEHICLE_DATA_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_VEHICLE_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_VEHICLE_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchVehicleDetails = (id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_VEHICLE_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchVehicleDetailsURL(id),
      });
      console.log('response --EDIT_VENDOR_DETAILS--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: FETCH_VEHICLE_DETAILS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_VEHICLE_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_VEHICLE_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getPaymentData = (value) => {
  //
  return async (dispatch) => {
    let response = {};
    let forSearch = value?.length > 0 ? value : '';

    try {
      dispatch({type: 'FETCH_PAYMENT_MAPPING'});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: 'finance/payment-entity' + forSearch,
      });

      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: 'COMPLETE_PAYMENT_MAPPING',
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FETCH_PAYMENT_MAPPING_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FETCH_PAYMENT_MAPPING_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const approveRejectPayment = (value) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: 'finance/payment-entity',
        data: value,
      });
      console.log('response --FETCH_VEHICLE_DATA_DETAILS--', response);
      const {data, status} = response || {};

      if (status) {
        dispatch({
          type: 'COMPLETE_APPROVE_REJECT_PAYMENT',
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
      }
    } catch (err) {}
    return response;
  };
};

export const savePaymentData = (value) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SAVE_PAYMENT_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.savePaymentDataUrl(),
        data: value,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: SAVE_PAYMENT_DATA_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: SAVE_PAYMENT_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SAVE_PAYMENT_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getVehicleDocument = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_VEHICLE_GET_DOCUMENT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.getVehicleDocumentUrl(),
        data: requestData,
      });
      console.log('response --FETCH_VEHICLE_GET_DOCUMENT--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_VEHICLE_GET_DOCUMENT_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_VEHICLE_GET_DOCUMENT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_VEHICLE_GET_DOCUMENT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchEditDriverDetails = (id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DRIVER_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchDriverDetailsURL(id),
      });
      console.log('response --EDIT_VENDOR_DETAILS--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: FETCH_DRIVER_DETAILS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_DRIVER_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_DRIVER_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editVehicleDetails = (requestData, id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_VEHCILE_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.editVehicleDetailsURL(id),
        isMAPI: true,
        data: requestData,
      });
      console.log('response --EDIT_VENDOR_DETAILS--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: EDIT_VEHCILE_DETAILS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_VEHCILE_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_VEHCILE_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const deleteVehicleDocs = (requestData, id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DELETE_VEHCILE_DOCS});
      response = await doRequest({
        method: REQUEST_TYPE.DELETE,
        url: Records.editVehicleDetailsURL(id),
        isMAPI: true,
        data: requestData,
      });
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: DELETE_VEHCILE_DOCS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: DELETE_VEHCILE_DOCS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DELETE_VEHCILE_DOCS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editDriverDetails = (requestData, id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_DRIVER_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.PUT,
        url: Records.editDriverDetailsURL(id),
        isMAPI: true,
        data: requestData,
      });
      console.log('response --EDIT_VENDOR_DETAILS--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: EDIT_DRIVER_DETAILS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_DRIVER_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_DRIVER_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addVehicle = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_VEHICLE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.addVehicleURL(),
        isMAPI: true,
        data: requestData,
      });
      console.log('response --EDIT_VENDOR_DETAILS--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: ADD_VEHICLE_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: ADD_VEHICLE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_VEHICLE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addDriver = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_DRIVER});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.addDriverURL(),
        data: requestData,
      });
      console.log('response --EDIT_VENDOR_DETAILS--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: ADD_DRIVER_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: ADD_DRIVER_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_DRIVER_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchDriverLicense = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DRIVER_LICENSE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.fetchDriverLicenseURL(),
        data: requestData,
      });
      console.log('response --EDIT_VENDOR_DETAILS--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: FETCH_DRIVER_LICENSE_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_DRIVER_LICENSE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_DRIVER_LICENSE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editDriverPhone = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_DRIVER_PHONE_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.editDriverPhoneURL(),
        data: requestData,
      });
      console.log('response --MARK_TRIP_AS_CLOSE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_DRIVER_PHONE_DETAILS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: EDIT_DRIVER_PHONE_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_DRIVER_PHONE_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addRouteTat = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_ROUTE_TAT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.addRouteTatURL(),
        data: requestData,
      });
      console.log('response --MARK_TRIP_AS_CLOSE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: ADD_ROUTE_TAT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: ADD_ROUTE_TAT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_ROUTE_TAT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const editRouteTat = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_ROUTE_TAT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.addRouteTatURL(),
        data: requestData,
      });
      console.log('response --MARK_TRIP_AS_CLOSE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_ROUTE_TAT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: EDIT_ROUTE_TAT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_ROUTE_TAT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addCityRecord = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_CITY_RECORD});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.addCityRecordURL(),
        data: requestData,
      });
      console.log('response --MARK_TRIP_AS_CLOSE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: ADD_CITY_RECORD_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: ADD_CITY_RECORD_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_CITY_RECORD_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editCityRecord = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_CITY_RECORD});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.addCityRecordURL(),
        data: requestData,
      });
      console.log('response --MARK_TRIP_AS_CLOSE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_CITY_RECORD_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: EDIT_CITY_RECORD_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_CITY_RECORD_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addConsignor = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_CONSIGNOR});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.addConsignorURL(),
        data: requestData,
      });
      console.log('response --MARK_TRIP_AS_CLOSE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: ADD_CONSIGNOR_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: ADD_CONSIGNOR_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_CONSIGNOR_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const editConsignor = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_CONSIGNOR});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.addConsignorURL(),
        data: requestData,
      });
      console.log('response --MARK_TRIP_AS_CLOSE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_CONSIGNOR_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: EDIT_CONSIGNOR_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_CONSIGNOR_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const updateAvailability = (id, requestData = {}) => {
  console.log('.....', requestData, id);
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: MARK_INACTIVE_VEHICLE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.updateVehicleAvailabilityURL(id),
        data: requestData,
      });
      console.log('response --MARK_TRIP_AS_CLOSE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: MARK_INACTIVE_VEHICLE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: MARK_INACTIVE_VEHICLE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: MARK_INACTIVE_VEHICLE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const editDistance = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_DISTANCE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.editDistanceURL(),
        data: requestData,
      });
      console.log('RESPONSE FOR EDIT DISTANCE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_DISTANCE_DETAILS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: EDIT_DISTANCE_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_DISTANCE_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export default (state = initialState, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    case ADD_DRIVER:
    case EDIT_DRIVER_DETAILS:

    case EDIT_DRIVER_PHONE_DETAILS:
      return {
        ...state,
        isFetching: true,
      };
    case EDIT_DRIVER_PHONE_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        updatedDriverPhoneData: payload,
      };
    case EDIT_DRIVER_PHONE_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
      };
    case ADD_VEHICLE:
    case EDIT_VEHCILE_DETAILS:
    case MARK_INACTIVE_VEHICLE:
    case ADD_CITY_RECORD:
    case ADD_CONSIGNOR:
    case ADD_ROUTE_TAT:
      return {
        ...state,
        isFetching: true,
      };
    case ADD_DRIVER_COMPLETED:
    case EDIT_DRIVER_DETAILS_COMPLETED:
    case ADD_VEHICLE_COMPLETED:
    case EDIT_VEHCILE_DETAILS_COMPLETED:
    case MARK_INACTIVE_VEHICLE_COMPLETED:
      return {
        ...state,
        isFetching: false,
        success: true,
      };
    case ADD_CITY_RECORD_COMPLETED:
    case ADD_CONSIGNOR_COMPLETED:
    case ADD_ROUTE_TAT_COMPLETED:
      return {
        ...state,
        isFetching: false,
      };
    case ADD_DRIVER_FAILED:
    case EDIT_DRIVER_DETAILS_FAILED:
    case ADD_VEHICLE_FAILED:
    case EDIT_VEHCILE_DETAILS_FAILED:
    case MARK_INACTIVE_VEHICLE_COMPLETED:
    case ADD_CITY_RECORD_FAILED:
    case ADD_CONSIGNOR_FAILED:
    case ADD_ROUTE_TAT_FAILED:
      return {
        ...state,
        isFetching: false,
        error: payload.error,
      };

    case FETCH_DRIVER_LICENSE:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DRIVER_LICENSE_COMPLETED:
      return {
        ...state,
        loading: false,
      };
    case FETCH_DRIVER_LICENSE_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };

    case 'FETCH_PAYMENT_MAPPING':
      return {
        ...state,
        payment_mapping: true,
      };
    case 'COMPLETE_PAYMENT_MAPPING':
      return {
        ...state,
        payment_mapping_data: payload.beneficiary_data,
        payment_mapping: false,
        total: payload.total_data_count,
      };

    case 'COMPLETE_APPROVE_REJECT_PAYMENT':
      let allData = state.payment_mapping_data;

      let index = allData.findIndex((item) => {
        return item.id === payload.id;
      });
      allData[index].status = payload.status;
      return {
        ...state,
        payment_mapping_data: allData,
      };

    case 'SAVE_PAYMENT_DATA':
      return {
        ...state,
        isPaymentDataSave: true,
      };
    case 'SAVE_PAYMENT_DATA_COMPLETED':
      let allPaymentData = state.payment_mapping_data; // original array of Obj
      let idToReplace = payload.id;
      let modifiedData = allPaymentData.map((data, index) => {
        if (idToReplace === data.id) {
          return payload;
        } else {
          return data;
        }
      });
      return {
        ...state,
        isPaymentDataSave: false,
        payment_mapping_data: modifiedData,
        savedPaymentData: payload,
      };

    case 'SAVE_PAYMENT_DATA_FAILED':
      return {
        ...state,
        isPaymentDataSave: false,
        error: payload.error,
      };

    case 'FETCH_PAYMENT_MAPPING_FAILED':
      return {
        ...state,
        payment_mapping: false,
      };
    case FETCH_VEHICLE_DATA:
      return {
        ...state,
        fetchVehicleloading: true,
      };
    case FETCH_VEHICLE_DATA_COMPLETED:
      return {
        ...state,
        fetchVehicleloading: false,
        vehicleData: payload,
      };
    case FETCH_VEHICLE_DATA_FAILED:
      return {
        ...state,
        fetchVehicleloading: false,
        error: payload.error,
      };

    case FETCH_DRIVER_DETAILS:
      return {
        ...state,
        data: {},
      };

    case FETCH_DRIVER_DETAILS_COMPLETED:
      return {
        ...state,
        data: payload,
      };
    case FETCH_DRIVER_DETAILS_FAILED:
      return {
        ...state,
        data: {},
      };

    case FETCH_VEHICLE_GET_DOCUMENT:
      return {
        ...state,
        // data: {},
        vehicleDocData: {},
      };

    case FETCH_VEHICLE_GET_DOCUMENT_COMPLETED:
      return {
        ...state,
        // data: payload,
        vehicleDocData: payload,
      };
    case FETCH_VEHICLE_GET_DOCUMENT_FAILED:
      return {
        ...state,
        // data: {},
        vehicleDocData: {},
      };

    case OPEN_WARNING_MODAL:
      return {
        visible: true,
        modalType: payload.modalType,
        entityId: payload.entityId,
        //entity id - booking id, trip id etc
        data: payload.data,
        warning_active_tab: payload.warning_active_tab,
      };
    case CLOSE_WARNING_MODAL:
      return {
        visible: false,
        warning_active_tab: payload.warning_active_tab,
      };
    case SWITCH_TAB:
      return {
        warning_active_tab: payload.warning_active_tab,
      };
    case FETCH_VEHICLE_DOCUMENT_HISTORY:
      return {
        ...state,
        historyData: {},
        IsLoading: true,
      };
    case FETCH_VEHICLE_DOCUMENT_HISTORY_COMPLETED:
      return {
        ...state,
        historyData: payload,
        IsLoading: false,
      };
    case FETCH_VEHICLE_DOCUMENT_HISTORY_FAILED:
      return {
        ...state,
        historyData: {},
        IsLoading: false,
      };
    case FETCH_GENERAL_VEHICLE_DATA:
      return {
        ...state,
        fetchGeneralVehicleloading: true,
      };
    case FETCH_GENERAL_VEHICLE_DATA_COMPLETED:
      return {
        ...state,
        fetchGeneralVehicleloading: false,
        generalVehicleData: payload,
      };
    case FETCH_GENERAL_VEHICLE_DATA_FAILED:
      return {
        ...state,
        fetchGeneralVehicleloading: false,
        error: payload.error,
      };
    case DELETE_VEHCILE_DOCS:
      return {
        deleteDocsLoading: true,
      };
    case DELETE_VEHCILE_DOCS_COMPLETED:
      return {
        deleteDocsLoading: false,
      };
    case DELETE_VEHCILE_DOCS_FAILED:
      return {
        deleteDocsLoading: false,
      };
    default: {
      if (payload.bookings) {
        return {...state, ...payload.records};
      }
      return state;
    }
  }
};
