import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Records} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isDataFetch: false,
  billingEntityDataObj: {},
  billingEntityDataIds: [],
  isApproveReject: false,
  isBillingEntityDataSave: false,
};

export const BILLING_ENTITY_DATA = 'BILLING_ENTITY_DATA';
export const BILLING_ENTITY_DATA_COMPLETED = 'BILLING_ENTITY_DATA_COMPLETED';
export const BILLING_ENTITY_DATA_FAILED = 'BILLING_ENTITY_DATA_FAILED';

export const EDIT_BILLING_ENTITY_DATA = 'EDIT_BILLING_ENTITY_DATA';
export const EDIT_BILLING_ENTITY_DATA_COMPLETED =
  'EDIT_BILLING_ENTITY_DATA_COMPLETED';
export const EDIT_BILLING_ENTITY_DATA_FAILED =
  'EDIT_BILLING_ENTITY_DATA_FAILED';

export const APPROVE_OR_REJECT_BILLING_ENTITY = 'BILLING_ENTITY_DATA';
export const APPROVE_OR_REJECT_BILLING_ENTITY_COMPLETED =
  'APPROVE_OR_REJECT_BILLING_ENTITY_COMPLETED';
export const APPROVE_OR_REJECT_BILLING_ENTITY_FAILED =
  'APPROVE_OR_REJECT_BILLING_ENTITY_FAILED';

export const approveOrRejectBillingEntity = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: APPROVE_OR_REJECT_BILLING_ENTITY});
      response = await doRequest({
        method: REQUEST_TYPE.PATCH,
        url: Records.fetchApproveOrRejectURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: APPROVE_OR_REJECT_BILLING_ENTITY_COMPLETED,
          payload: {...data},
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: APPROVE_OR_REJECT_BILLING_ENTITY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: APPROVE_OR_REJECT_BILLING_ENTITY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchBillingEntity = (page, search, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: BILLING_ENTITY_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchBillingEntityURL(page, search),
      });
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: BILLING_ENTITY_DATA_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: BILLING_ENTITY_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: BILLING_ENTITY_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editBillingEntity = (value) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_BILLING_ENTITY_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.editBillingEntityUrl(),
        data: value,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: EDIT_BILLING_ENTITY_DATA_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_BILLING_ENTITY_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_BILLING_ENTITY_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case BILLING_ENTITY_DATA:
      return {
        ...state,
        isDataFetch: true,
        hasError: false,
        error: '',
      };
    case BILLING_ENTITY_DATA_COMPLETED:
      return {
        ...state,
        billingEntityDataObj: payload.billing_entity_data,
        billingEntityDataIds: payload.billing_entity_ids,
        isDataFetch: false,
      };
    case BILLING_ENTITY_DATA_FAILED:
      return {
        ...state,
        isDataFetch: false,
        hasError: true,
        error: '',
      };
    case EDIT_BILLING_ENTITY_DATA:
      return {
        ...state,
        isBillingEntityDataSave: true,
      };
    case EDIT_BILLING_ENTITY_DATA_COMPLETED:
      let billingData = state.billingEntityDataObj;

      const index = Object.values(billingData).findIndex(
        (item) => item?.basicInfo?.id === payload.id,
      );

      if (index !== -1) {
        const key = Object.keys(billingData)[index];
        billingData[key] = {
          basicInfo: {
            id: payload.id,
            pan_name: payload.pan_name,
            pan_number: payload.pan_number,
            pan_card_url: payload.pan_card_url,
            tds_deduction: payload.tds_deduction,
            tds_deduction_certificate: payload.tds_deduction_certificate,
            tds_effective_date: payload.tds_effective_date,
            tds: payload.tds,
            status: payload.status,
            image_details: payload.image_details,
          },
        };
      }
      return {
        ...state,
        isBillingEntityDataSave: false,
        billingEntityDataObj: billingData,
      };

    case EDIT_BILLING_ENTITY_DATA_FAILED:
      return {
        ...state,
        isBillingEntityDataSave: false,
        error: payload.error,
      };
    case APPROVE_OR_REJECT_BILLING_ENTITY:
      return {
        ...state,
        isApproveReject: true,
      };
    case APPROVE_OR_REJECT_BILLING_ENTITY_COMPLETED:
      return {
        ...state,

        isApproveReject: false,
      };
    case APPROVE_OR_REJECT_BILLING_ENTITY_FAILED:
      return {
        ...state,
        isApproveReject: false,
        hasError: true,
        error: '',
      };
    default:
      return state;
  }
};
