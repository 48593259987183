import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Records} from '../../../helpers/Urls';
import {EDIT_ROUTE_TAT_COMPLETED, ADD_ROUTE_TAT_COMPLETED} from '../../record';
import {merge} from 'lodash';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  routeIds: [],
  routeCount: 0,
  route_tats: {},
  search: '',
  resetPage: false,
  headerVisible: false,
};

const FETCH_ROUTE_TATS = 'FETCH_ROUTE_TATS';
const FETCH_ROUTE_TATS_COMPLETED = 'FETCH_ROUTE_TATS_COMPLETED';
const FETCH_ROUTE_TATS_FAILED = 'FETCH_ROUTE_TATS_FAILED';

const TOGGLE_ROUTE_ADD_HEADER = 'TOGGLE_ROUTE_ADD_HEADER ';
const RESET_PAGE = 'RESET_PAGE';

export const toggleAddRouteHeader = () => {
  return (dispatch) => {
    dispatch({type: TOGGLE_ROUTE_ADD_HEADER});
  };
};

export const fetchRouteTats = (page, search, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_ROUTE_TATS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchRouteTatsURL(page, search),
      });
      // console.log('response --FETCH_VENDOR_DETAILS_COMPLETED--', response);
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }

      if (status === true) {
        dispatch({type: FETCH_ROUTE_TATS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_ROUTE_TATS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_ROUTE_TATS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

const setRouteIds = (state, payload = {}) => {
  const {route_tat_list} = payload;
  if (state?.routeIds?.length < 30) {
    const updatedState = [...state.routeIds, ...route_tat_list];
    return [...updatedState];
  } else return state.routeIds;
};

const setUpdatedRouteTat = (state, payload = {}) => {
  const {route_tats} = payload;
  if (route_tats) {
    const oldRoutes = {...state.route_tats};
    const newRoutes = {...route_tats};
    const updatedState = {...oldRoutes, ...newRoutes};
    console.log('merged route tats', updatedState, oldRoutes, newRoutes);
    return {...state, requesting: false, route_tats: updatedState};
  } else return state;
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_ROUTE_TATS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_ROUTE_TATS_COMPLETED:
      // case EDIT_ROUTE_TAT_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        routes: payload?.routes,
        routesId: payload?.routes_id,
        routeCount: payload?.total,
        total: payload?.total,
        search: payload?.query_string,
        route_tats: payload?.route_tats,
        routeIds: payload?.route_tat_list,
      };
    case FETCH_ROUTE_TATS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        routeIds: [],
        route_tats: {},
      };
    case EDIT_ROUTE_TAT_COMPLETED:
      return setUpdatedRouteTat(state, payload);
    // return {
    //   ...state,
    //   route_tats: {...state.route_tats, ...payload?.route_tats},
    // };
    case ADD_ROUTE_TAT_COMPLETED:
      return {
        ...state,
        route_tats: {...state.route_tats, ...payload?.route_tats},
        routeIds: payload?.route_tat_list,
      };
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };
    case TOGGLE_ROUTE_ADD_HEADER:
      return {...state, headerVisible: !state.headerVisible};
    default:
      return state;
  }
};
