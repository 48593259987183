import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Records} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isRequesting: false,
  hasError: false,
  error: '',
  drivers: {},
  driverIds: [],
  driverCount: 0,
  search: '',
  resetPage: false,
  isPrintDriverDetailsLoading: false,
};

const FETCH_DRIVER_DETAILS = 'FETCH_DRIVER_DETAILS';
const FETCH_DRIVER_DETAILS_COMPLETED = 'FETCH_DRIVER_DETAILS_COMPLETED';
const FETCH_DRIVER_DETAILS_FAILED = 'FETCH_DRIVER_DETAILS_FAILED';

const PRINT_DRIVER_DETAILS = 'PRINT_DRIVER_DETAILS';
const PRINT_DRIVER_DETAILS_COMPLETED = 'PRINT_DRIVER_DETAILS_COMPLETED';
const PRINT_DRIVER_DETAILS_FAILED = 'PRINT_DRIVER_DETAILS_FAILED';

const RESET_PAGE = 'RESET_PAGE';

const RESET_DRIVER_FILTER = 'RESET_DRIVER_FILTER';

export const resetFilters = () => {
  return (dispatch) => {
    dispatch({type: RESET_DRIVER_FILTER});
  };
};

export const fetchDriverRecords = (isZast, page, search, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DRIVER_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: isZast
          ? Records.fetchZastDriverRecordURL(page, search)
          : Records.fetchDriverRecordsURL(page, search),
      });
      // console.log('response --FETCH_VENDOR_DETAILS_COMPLETED--', response);
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }

      if (status === true) {
        dispatch({type: FETCH_DRIVER_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_DRIVER_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_DRIVER_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const printDriverDetails = (id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: PRINT_DRIVER_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: `vendor/pdf-driver-form/${id}`,
      });
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: PRINT_DRIVER_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: PRINT_DRIVER_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: PRINT_DRIVER_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case PRINT_DRIVER_DETAILS:
      return {
        ...state,
        isPrintDriverDetailsLoading: true,
      };
    case PRINT_DRIVER_DETAILS_COMPLETED:
      return {
        ...state,
        isPrintDriverDetailsLoading: false,
      };
    case PRINT_DRIVER_DETAILS_FAILED:
      return {
        ...state,
        isPrintDriverDetailsLoading: false,
      };
    case FETCH_DRIVER_DETAILS:
      return {
        ...state,
        isRequesting: true,
        hasError: false,
        error: '',
      };
    case FETCH_DRIVER_DETAILS_COMPLETED:
      console.log(' payload.driver_count', payload);
      return {
        ...state,
        isRequesting: false,
        hasError: false,
        drivers: payload?.drivers,
        driverIds: payload?.driver_list,
        zastCount: payload?.Counts?.zast,
        otherDriverCount: payload?.Counts?.other,
        total: payload?.total,
        search: payload.query_string,
      };
    case FETCH_DRIVER_DETAILS_FAILED:
      return {
        ...state,
        isRequesting: false,
        hasError: true,
        error: payload.error,
        driverIds: [],
      };
    case RESET_PAGE:
      return {...state, resetPage: !state.resetPage};
    case RESET_DRIVER_FILTER:
      return {...state, search: ''};
    default:
      return state;
  }
};
