import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Records} from '../../../helpers/Urls';
import {merge} from 'lodash';

const INITIAL_STATE = {
  isFetching: false, //to fetch distance maps
  routes: {},
  routeIds: [],
  total: 0,
  search: '',
  headerVisible: false,
  requesting: false, //For add/edit distance map
  distanceCount: 0,
  resetPage: false,
};

const FETCH_DISTANCE_MAP = 'FETCH_DISTANCE_MAP';
const FETCH_DISTANCE_MAP_COMPLETED = 'FETCH_DISTANCE_MAP_COMPLETED';
const FETCH_DISTANCE_MAP_FAILED = 'FETCH_DISTANCE_MAP_FAILED';

const ADD_UPDATE_DISTANCE_MAP = 'ADD_UPDATE_DISTANCE_MAP';
const ADD_UPDATE_DISTANCE_MAP_COMPLETED = 'ADD_UPDATE_DISTANCE_MAP_COMPLETED';
const ADD_UPDATE_DISTANCE_MAP_FAILED = 'ADD_UPDATE_DISTANCE_MAP_FAILED';

const TOGGLE_HEADER = 'TOGGLE_HEADER ';
const RESET_PAGE = 'RESET_PAGE';

export const toggleHeader = () => {
  return (dispatch) => {
    dispatch({type: TOGGLE_HEADER});
  };
};

export const fetchDistanceMap = (page, search, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DISTANCE_MAP});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchDistanceMapURL(page, search),
      });
      console.log('response --FETCH_DISTANCE_MAP_COMPLETED--', response);
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }

      if (status === true) {
        dispatch({type: FETCH_DISTANCE_MAP_COMPLETED, payload: data});
      } else {
        const {message: error = 'Error'} = response || {};
        dispatch({type: FETCH_DISTANCE_MAP_FAILED, payload: {error}});
      }
    } catch (err) {
      dispatch({
        type: FETCH_DISTANCE_MAP_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addUpdateDistanceMap = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_UPDATE_DISTANCE_MAP});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.addUpdateDistanceMapURL(),
        data: requestData,
      });
      console.log('response --ADD_UPDATE_DISTANCE_MAP--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: ADD_UPDATE_DISTANCE_MAP_COMPLETED, payload: data});
      } else {
        const {message: error = 'Error'} = response || {};
        dispatch({type: ADD_UPDATE_DISTANCE_MAP_FAILED, payload: {error}});
      }
    } catch (err) {
      dispatch({
        type: ADD_UPDATE_DISTANCE_MAP_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

const setUpdatedDistanceMap = (state, payload = {}) => {
  const {route_distance} = payload;
  if (route_distance) {
    const oldRoutes = {...state.routes};
    const newRoutes = {...route_distance};
    const updatedState = merge(oldRoutes, newRoutes);
    return {...state, requesting: false, routes: updatedState};
  } else return state;
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_DISTANCE_MAP:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_DISTANCE_MAP_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        routes: payload?.route_distance,
        routeIds: payload?.route_list,
        total: payload?.total,
        search: payload?.query_string,
        distanceCount: payload?.total,
      };
    case FETCH_DISTANCE_MAP_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        distanceIds: [],
      };
    case TOGGLE_HEADER:
      return {...state, headerVisible: !state.headerVisible};
    case ADD_UPDATE_DISTANCE_MAP:
      return {...state, requesting: true};
    case ADD_UPDATE_DISTANCE_MAP_FAILED:
      return {...state, requesting: false};
    case ADD_UPDATE_DISTANCE_MAP_COMPLETED:
      return setUpdatedDistanceMap(state, payload);
    // return {
    //   ...state,
    //   routes: payload?.route_distance,
    //   routeIds: payload?.route_list,
    //   requesting: false,
    // };
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };
    default:
      return state;
  }
};
