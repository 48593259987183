import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Records} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  customerIds: [],
  customerCount: 0,
  customerFinanceCount: 0,
  search: '',
  resetPage: false,
  isRequesting: false,
};

const FETCH_CUSTOMER_DETAILS = 'FETCH_CUSTOMER_DETAILS';
const FETCH_CUSTOMER_DETAILS_COMPLETED = 'FETCH_CUSTOMER_DETAILS_COMPLETED';
const FETCH_CUSTOMER_DETAILS_FAILED = 'FETCH_CUSTOMER_DETAILS_FAILED';

const APPROVE_CUSTOMER = 'APPROVE_CUSTOMER';
const APPROVE_CUSTOMER_COMPLETED = 'APPROVE_CUSTOMER_COMPLETED';
const APPROVE_CUSTOMER_FAILED = 'APPROVE_CUSTOMER_FAILED';

const RESET_PAGE = 'RESET_PAGE';

export const fetchCustomerDetails = (
  isFinance = false,
  page,
  search,
  resetPage = true,
) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_CUSTOMER_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: isFinance
          ? Records.fetchCustomerFinanceDetailsURl(page, search)
          : Records.fetchCustomerDetailsURL(page, search),
      });
      console.log('response --FETCH_CUSTOMER_DETAILS_COMPLETED--', response);
      const {data, status} = response || {};

      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status === true) {
        dispatch({type: FETCH_CUSTOMER_DETAILS_COMPLETED, payload: data});
      } else {
        const {message: error = 'Error'} = response || {};
        dispatch({type: FETCH_CUSTOMER_DETAILS_FAILED, payload: {error}});
      }
    } catch (err) {
      dispatch({
        type: FETCH_CUSTOMER_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//action= APPROVAL_ACTIONS.APPROVE, customer_id
export const approveCustomer = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: APPROVE_CUSTOMER});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.approveCustomerURL(),
        data: requestData,
      });
      console.log('response --APPROVE_CUSTOMER--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: APPROVE_CUSTOMER_COMPLETED, payload: data});
      } else {
        const {message: error = 'Error'} = response || {};
        dispatch({type: APPROVE_CUSTOMER_FAILED, payload: {error}});
      }
    } catch (err) {
      dispatch({
        type: APPROVE_CUSTOMER_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_CUSTOMER_DETAILS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_CUSTOMER_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        customerIds: payload?.customers_list,
        customerCount: payload?.customers_count,
        customerFinanceCount: payload?.financial_count,
        total: payload?.total,
        search: payload.query_string,
      };
    case FETCH_CUSTOMER_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        customerIds: [],
      };
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };
    case APPROVE_CUSTOMER:
      return {...state, isRequesting: true};
    case APPROVE_CUSTOMER_COMPLETED:
    case APPROVE_CUSTOMER_FAILED:
      return {...state, isRequesting: false};
    default:
      return state;
  }
};
